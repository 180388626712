import { useState } from "react"
import { Api, ApplicationOne, ArrowRight } from "@icon-park/react";
import { Grid, Card, Text, Flex, ActionIcon } from "@mantine/core";

const Options = ({ onSelect }: any) => {

    // const [selected, setSelected] = useState("")

    const handleSelect = (value: string) => {
        onSelect(value)
    }

    return <Grid justify="center" >
        <Grid.Col xs={7}>
            <Grid>
                <Grid.Col xs={12} md={6}>
                    <Card shadow="sm" padding="lg" radius="md" withBorder>
                        <Grid>
                            <Grid.Col xs={4}>
                                <Api size={60} fill="#088aff" />
                            </Grid.Col>
                            <Grid.Col xs={6}>
                                <Flex justify="center" align="center" h={"100%"}>
                                    <Text
                                        variant="text"
                                        color="gray"
                                        align="center"
                                        fz={26}
                                        fw={800}>
                                        API 
                                    </Text>
                                </Flex>
                            </Grid.Col>
                            <Grid.Col xs={2}>
                                <Flex styles={{ cursor: "pointer" }} justify={"center"} align={"center"} h={"100%"} onClick={() => handleSelect("api")}>
                                    <ActionIcon size={50} radius={"xl"} color="blue" variant="light"><ArrowRight theme="outline" size="25" fill="#333" /></ActionIcon>
                                </Flex>
                            </Grid.Col>
                        </Grid>
                    </Card>
                </Grid.Col>
                <Grid.Col xs={12} md={6}>
                    <Card shadow="sm" padding="lg" radius="md" withBorder>
                        <Grid>
                            <Grid.Col xs={4}>
                                <ApplicationOne theme="outline" size={60} fill="#088aff" />
                            </Grid.Col>
                            <Grid.Col xs={6}>
                                <Flex justify="center" align="center" h={"100%"}>
                                    <Text variant="text" color="gray" align="center" fz={26} fw={800}>
                                        SDK
                                    </Text>
                                </Flex>
                            </Grid.Col>
                            <Grid.Col xs={2}>
                                <Flex styles={{ cursor: "pointer" }} justify={"center"} align={"center"} h={"100%"} onClick={() => handleSelect("sdk")}>
                                    <ActionIcon size={50} radius={"xl"} color="blue" variant="light"><ArrowRight theme="outline" size="25" fill="#333" /></ActionIcon>
                                </Flex>
                            </Grid.Col>
                        </Grid>
                    </Card>
                </Grid.Col>
            </Grid>
        </Grid.Col>
    </Grid>
}

export default Options;