import React, { useState, useContext, useEffect } from "react";
import { Grid, LoadingOverlay, Button, Text } from "@mantine/core";
import { UploadPicture } from "@icon-park/react";
import Basic from "../../helpers/basicHelper";

const UploadInputAWS = ({
    transactionId,
    onSuccess
}: any) => {

    const [isLoading, setIsLoading] = useState(false);
    const [pickedFile, setPickedFile] = useState(null);
    const [urlUploaded, setUrlUploaded] = useState("");

    const { RegisterDeposit } = Basic()

    const changeHandler = async (event: any) => {
        
        setIsLoading(true)
        const selectedFile = event.target.files[0];
        const formatArray = selectedFile?.name?.split(".");

        const format = formatArray[formatArray?.length - 1];

        setPickedFile(selectedFile);
       
        // const { url, success } = await RegisterDeposit(transactionId, selectedFile);

        // if (success) {
        //     setUrlUploaded(url);
            onSuccess(selectedFile)
        // }

        setIsLoading(false)
    };

    return (<>
        <input
            accept="image/*"
            id="contained-button-file"
            multiple
            type="file"
            hidden
            onChange={changeHandler}
            disabled={isLoading}
        />
        <label htmlFor="contained-button-file">
            <Grid
                justify="center"
                align="center"
                style={{ border: "4px dotted #000033", padding: 30, borderRadius: 10, cursor: "pointer", maxWidth: 400, margin: "auto" }}>
                <Grid.Col xs={12}>
                    <Text variant="body2" align="center" style={{ marginBottom: 10 }}>
                        SUBIR COMPROBANTE
                    </Text>
                </Grid.Col>
                <Grid.Col xs={12} style={{ margin: "auto", textAlign: "center" }} mt={10} mb={10}>
                    <UploadPicture theme="outline" size="48" fill="#000033" />
                </Grid.Col>
                <Grid.Col xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        size="small"
                        fullWidth
                    >
                        UPLOAD VOUCHER
                    </Button>
                </Grid.Col>
            </Grid>
            {isLoading &&  <LoadingOverlay visible={isLoading} overlayBlur={2}/>}
        </label>
    </>)
}

export default UploadInputAWS;