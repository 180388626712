// const __URLS = {
//     'staging': "https://api-staging.traund.xyz",
//     'beta': "https://api-beta.traund.xyz",
//     'production': "https://api.traund.com"
// }

const __getBaseUrl = () => {
    switch (process.env.REACT_APP_ENV) {
        case 'staging': return "https://api-staging.traund.xyz";
        case 'beta': return "https://api-beta.traund.xyz";
        case 'production': return "https://api.traund.com";
        default: return "https://api-beta.traund.xyz";
    }
};

const __baseUrl = __getBaseUrl();

export const ENDPOINTS = {
    calculate: `${__baseUrl}/transaction/calculate`,
    authorize: `${__baseUrl}/authorize`,
    session_start: `${__baseUrl}/session/start`,
    create: `${__baseUrl}/transaction/create`,
    registerDeposit: (id: string) => `${__baseUrl}/transaction/${id}/register-deposit`,
    getStatus: (id: string) => `${__baseUrl}/transaction/${id}/status`
}