import { Card, Image, Grid, LoadingOverlay, Alert } from "@mantine/core";
import Form from "./form";
import { useState } from "react";
import Basic from "../../helpers/basicHelper";

const Auth = ({ onSuccess }: any) => {

    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState<Boolean>(false)
    const [hasError, setHasError] = useState(false);

    const { Authorize, SessionStart } = Basic();

    const handleClick = async ({ apiKey, apiSecret }: any) => {
        setIsLoading(true)

        let { success, sessionRequestId } = await Authorize({ apiKey, apiSecret });
        if (!success) {
            setIsLoading(false)
            setIsSuccess(false)
            setHasError(true)
        }

        const sessionResponse = await SessionStart({
            sessionRequestId,
            activityId: "sdk-start",
            options: {
                "locationId": "66206156adcbcf3c392a802d"
            }
        });
        success = sessionResponse.success;
        if (!success) {
            setIsLoading(false)
            setIsSuccess(false)
            setHasError(true)
        }

        setIsLoading(false)
        setIsSuccess(true)
        setHasError(false)
        sessionStorage.setItem("token", sessionResponse.token);
        onSuccess()
    }

    return (<Card>
        <Grid justify="center" mt={"5%"}>
            <Grid.Col xs={3}>
                <Grid justify="center">
                    <Image src={"https://images-traund.s3.amazonaws.com/Global_Images/Logo_New_Brand_Color.svg"} width={120} />
                </Grid>
                <Grid.Col>
                    <Form onSubmit={handleClick} />
                </Grid.Col>
                <Grid.Col>
                    {hasError &&
                        <Alert color="yellow">
                            Error!
                        </Alert>}
                </Grid.Col>
            </Grid.Col>
        </Grid>
        <LoadingOverlay visible={isLoading} overlayBlur={2} />
    </Card>)
}

export default Auth;