import { useState, useEffect } from "react";
import { Grid, TextInput, Select, Button, Card, LoadingOverlay, Text, Alert, Code } from "@mantine/core";
import { useForm } from '@mantine/form';
import Basic from "../../helpers/basicHelper";
import { countries } from "../../data/countries";
import Payload from "./payload";
import Response from "./response";
import useCalculatorCountries from "../../hook/useCalculatorCountries";

const Calculate = ({ onSubmit }: any) => {

    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState("");
    const [isCalculated, setIsCalculated] = useState(false)
    const [response, setResponse] = useState(null)

    const form = useForm({
        initialValues: {
            amountFrom: "0",
            countryFrom: "",
            amountTo: "0",
            countryTo: "",
        }
    });

    const { sources, targets } = useCalculatorCountries(form.getInputProps('countryFrom').value)

    const { Calculate } = Basic();

    const handleSubmit = async () => {

        setMessage("")
        setIsLoading(true)

        const { success, calculated, response } = await Calculate({
            ...form.values,
            currencyFrom: getCountryByCode(form.getInputProps("countryFrom").value)?.currency,
            currencyTo: getCountryByCode(form.getInputProps("countryTo").value)?.currency
        });

        if (success) {
            setIsLoading(false)
            setIsCalculated(true)
            sessionStorage.setItem("storeId", calculated?.storeId)
            form.setValues({ "amountTo": calculated?.amount })
            setResponse(response)
        }
        else {
            setIsLoading(false)
            sessionStorage.removeItem("token")
            setMessage("Token has expired!. Reload Page.")
        }
    }

    const getCountryByCode = (search: string) => {
        if (typeof search !== "string") return;
        return countries.find(e => e.code?.toLowerCase() === search?.toLowerCase());
    }

    // useEffect(() => {
    // }, [form.getInputProps("countryFrom").value])

    return (
        <Grid justify="center" mt={"xl"}>
            <Grid.Col xs={7}>
                <Card shadow="sm" padding="lg" radius="md" withBorder>
                    <Grid>
                        <Grid.Col xs={12} sm={5}>
                            {
                                !isCalculated ? <Payload
                                    amount={form.getInputProps("amountFrom").value}
                                    countryFrom={getCountryByCode(form.getInputProps("countryFrom").value)?.code}
                                    currencyFrom={getCountryByCode(form.getInputProps("countryFrom").value)?.currency}
                                    countryTo={getCountryByCode(form.getInputProps("countryTo").value)?.code}
                                    currencyTo={getCountryByCode(form.getInputProps("countryTo").value)?.currency}
                                /> : <Response response={response} />
                            }
                        </Grid.Col>
                        <Grid.Col xs={12} sm={7}>
                            <Grid>
                                <Grid.Col xs={12}>
                                    <Text align="center" fz={"xl"} color="blue" fw={600}>Calculate your transference</Text>
                                </Grid.Col>
                                <Grid.Col xs={6}>
                                    <TextInput
                                        placeholder="100.00"
                                        label="Amount From"
                                        {...form.getInputProps('amountFrom')}
                                        withAsterisk
                                    />
                                </Grid.Col>
                                <Grid.Col xs={6}>
                                    <Select
                                        //value={"TRAUND"}
                                        label={"From"}
                                        {...form.getInputProps('countryFrom')}
                                        data={[
                                            {
                                                value: "TRAUND",
                                                label: "Billetera Traund (USDT)"
                                            }
                                        ]} />
                                    {/* <Select
                                        label="Country"
                                        placeholder="Country"
                                        {...form.getInputProps('countryFrom')}
                                        data={sources.filter(x => x.value == 'TRAUND')} /> */}
                                </Grid.Col>
                                <Grid.Col xs={6}>
                                    <TextInput
                                        placeholder="0.0"
                                        label="Amount To"
                                        {...form.getInputProps('amountTo')}
                                        disabled
                                        withAsterisk
                                    />
                                </Grid.Col>
                                <Grid.Col xs={6}>
                                    <Select
                                        label="Country"
                                        placeholder="Country"
                                        {...form.getInputProps('countryTo')}
                                        data={targets} />
                                </Grid.Col>
                                <Grid.Col xs={12}>
                                    <Button size="md" onClick={handleSubmit} fullWidth>CALCULATE</Button>
                                </Grid.Col>
                                {
                                    isCalculated && <Grid.Col xs={12}>
                                        <Button size="md" color={"teal"} onClick={() => { onSubmit && onSubmit() }} fullWidth>NEXT</Button>
                                    </Grid.Col>
                                }
                            </Grid>
                        </Grid.Col>
                        {
                            message.length > 0 && <Grid.Col xs={12}>
                                <Alert color="orange">{message}</Alert>
                            </Grid.Col>
                        }
                        <LoadingOverlay visible={isLoading} overlayBlur={2} />
                    </Grid>
                </Card>
            </Grid.Col>
        </Grid>
    )
}

export default Calculate;