import axios from "axios";
import { ENDPOINTS } from "../data/endpoints"

const BasicService = {
    PostAuthorize: ({ apiKey, apiSecret }: any) => {
        return axios.post(ENDPOINTS.authorize,
            {
                "apiKey": apiKey,
                "apiSecret": apiSecret
            })
    },
    PostSessionStart: ({ sessionRequestId, activityId, options }: any) => {
        return axios.post(ENDPOINTS.session_start,
            {
                "sessionRequestId": sessionRequestId,
                "activityId": activityId,
                "options": options
            })
    },
    PostCreateTransaction: (model: any, token: string) => {
        return axios.post(ENDPOINTS.create, model, {
            headers: {
                "Authorization": token
            }
        })
    },
    PostRegisterDeposit: (transactionId: string, fd: any, token: string) => {
        return axios.post(ENDPOINTS.registerDeposit(transactionId), fd, {
            headers: {
                "Content-Type": 'multipart/form-data',
                "Authorization": token
            }
        })
    },
    // PostUpdateStatusTransaction : (model : any, token: string) => {
    //     return axios.post(ENDPOINTS.create, model, {
    //         headers: {
    //             "Authorization": token
    //         }
    //     })
    // },
    PostCalculate: (model: any, token: string) => {
        return axios.post(ENDPOINTS.calculate, model, {
            headers: {
                "Authorization": token
            }
        })
    },
    GetStatus: (id: string, token: string) => {
        return axios.get(ENDPOINTS.getStatus(id), {
            headers: {
                "Authorization": token
            }
        })
    }
}

export default BasicService