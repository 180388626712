export const CalculatorCountries = {
    "AR": {
        "collect": {
            "minAmount": 20,
            "maxAmount": 5000,
            "sources": [
                "CL", "CO", "EC", "MX", "PE", "PE-USD", "US"
            ],
            "enabled": true,
            "fee": {}
        },
        "middleAmount": {
            "rateBuy": 3.68,
            "rateSell": 3.68,
            "currency": "USD"
        },
        "code": "AR",
        "currencyCalc": "ARS",
        "currency": "ARS",
        "type": "country",
        "send": {
            "minAmount": 20,
            "maxAmount": 5000,
            "targets": [],
            "enabled": false,
            "fee": {}
        }
    },
    "CL": {
        "collect": {
            "minAmount": 20,
            "maxAmount": 5000,
            "sources": [
                "CO", "EC", "MX", "PE", "PE-USD", "US"
            ],
            "enabled": true,
            "fee": {}
        },
        "middleAmount": {
            "rateBuy": 3.68,
            "rateSell": 3.68,
            "currency": "USD"
        },
        "code": "CL",
        "currencyCalc": "CLP",
        "currency": "CLP",
        "type": "country",
        "send": {
            "minAmount": 20,
            "maxAmount": 5000,
            "targets": ["AR", "CO", "DO", "EC", "ES", "MX", "PE", "PE-USD", "US", "VE"],
            "enabled": true,
            "fee": {}
        }
    },
    "EC": {
        "collect": {
            "minAmount": 20,
            "maxAmount": 5000,
            "sources": [
                "CL", "CO", "EC", "MX", "PE", "PE-USD", "US"
            ],
            "enabled": false,
            "fee": {}
        },
        "middleAmount": {
            "rateBuy": 3.68,
            "rateSell": 3.68,
            "currency": "USD"
        },
        "code": "AR",
        "currencyCalc": "ARS",
        "currency": "ARS",
        "type": "country",
        "send": {
            "minAmount": 20,
            "maxAmount": 5000,
            "targets": ["AR", "CO", "DO", "EC", "ES", "MX", "PE", "PE-USD", "US", "VE"],
            "enabled": false,
            "fee": {}
        }
    },
    "CO": {
        "collect": {
            "minAmount": 20,
            "maxAmount": 5000,
            "sources": [
                "CL", "EC", "MX", "PE", "PE-USD", "US"
            ],
            "enabled": true,
            "fee": {}
        },
        "middleAmount": {
            "rateBuy": 3.68,
            "rateSell": 3.68,
            "currency": "USD"
        },
        "code": "CO",
        "currencyCalc": "COP",
        "currency": "COP",
        "type": "country",
        "send": {
            "minAmount": 20,
            "maxAmount": 5000,
            "targets": ["AR", "CL", "DO", "EC", "ES", "MX", "PE", "PE-USD", "US", "VE"],
            "enabled": true,
            "fee": {}
        }
    },
    "DO": {
        "collect": {
            "minAmount": 20,
            "maxAmount": 5000,
            "sources": [
                "CL", "EC", "CO", "MX", "PE", "PE-USD", "US"
            ],
            "enabled": true,
            "fee": {}
        },
        "middleAmount": {
            "rateBuy": 3.68,
            "rateSell": 3.68,
            "currency": "USD"
        },
        "code": "DO",
        "currencyCalc": "DOP",
        "currency": "DOP",
        "type": "country",
        "send": {
            "minAmount": 20,
            "maxAmount": 5000,
            "targets": [],
            "enabled": false,
            "fee": {}
        }
    },
    "ES": {
        "collect": {
            "minAmount": 20,
            "maxAmount": 5000,
            "sources": [
                "CL", "CO", "EC", "MX", "PE", "PE-USD", "US"
            ],
            "enabled": true,
            "fee": {}
        },
        "middleAmount": {
            "rateBuy": 3.68,
            "rateSell": 3.68,
            "currency": "USD"
        },
        "code": "ES",
        "currencyCalc": "EUR",
        "currency": "EUR",
        "type": "country",
        "send": {
            "minAmount": 20,
            "maxAmount": 5000,
            "targets": [],
            "enabled": false,
            "fee": {}
        }
    },
    "MX": {
        "collect": {
            "minAmount": 20,
            "maxAmount": 5000,
            "sources": [
                "CL", "CO", "EC", "PE", "PE-USD", "US"
            ],
            "enabled": true,
            "fee": {}
        },
        "middleAmount": {
            "rateBuy": 3.68,
            "rateSell": 3.68,
            "currency": "USD"
        },
        "code": "MX",
        "currencyCalc": "MXN",
        "currency": "MXN",
        "type": "country",
        "send": {
            "minAmount": 20,
            "maxAmount": 5000,
            "targets": ["AR", "CL", "CO", "DO", "EC", "ES", "PE", "PE-USD", "US", "VE"],
            "enabled": true,
            "fee": {}
        }
    },
    "PE": {
        "collect": {
            "minAmount": 20,
            "maxAmount": 5000,
            "sources": [
                "CL", "CO", "EC", "MX", "US"
            ],
            "enabled": true,
            "fee": {}
        },
        "middleAmount": {
            "rateBuy": 3.68,
            "rateSell": 3.68,
            "currency": "USD"
        },
        "code": "PE",
        "currencyCalc": "PEN",
        "currency": "PEN",
        "type": "country",
        "send": {
            "minAmount": 20,
            "maxAmount": 5000,
            "targets": ["AR", "CL", "CO", "DO", "EC", "ES", "MX", "US", "VE"],
            "enabled": true,
            "fee": {},
            "limits": {
                "VE": {
                    "minAmount": 15,
                    "maxAmount": 5000
                }
            }
        }
    },
    "PE-USD": {
        "collect": {
            "minAmount": 20,
            "maxAmount": 5000,
            "sources": [
                "CL", "CO", "EC", "MX", "US"
            ],
            "enabled": true,
            "fee": {}
        },
        "middleAmount": {
            "rateBuy": 3.68,
            "rateSell": 3.68,
            "currency": "USD"
        },
        "code": "PE-USD",
        "currencyCalc": "USD",
        "currency": "USD",
        "type": "country",
        "send": {
            "minAmount": 20,
            "maxAmount": 5000,
            "targets": ["AR", "CL", "CO", "DO", "EC", "ES", "MX", "US", "VE"],
            "enabled": true,
            "fee": {}
        }
    },
    "US": {
        "middleAmount": {
            "rateBuy": 1,
            "rateSell": 1,
            "currency": "USD"
        },
        "code": "US",
        "currencyCalc": "USD",
        "currency": "USD",
        "type": "country",
        "collect": {
            "minAmount": 20,
            "maxAmount": 5000,
            "enabled": true,
            "sources": [
                "CL", "CO", "EC", "MX", "PE", "PE-USD"
            ],
            "fee": {}
        },
        "send": {
            "minAmount": 20,
            "maxAmount": 5000,
            "enabled": true,
            "targets": ["AR", "CL", "CO", "DO", "EC", "ES", "MX", "PE", "PE-USD", "VE"],
            "fee": {}
        }
    },
    "VE": {
        "collect": {
            "minAmount": 20,
            "maxAmount": 5000,
            "sources": [
                "CL", "CO", "EC", "MX", "PE", "PE-USD", "US"
            ],
            "enabled": true,
            "fee": {}
        },
        "middleAmount": {
            "rateBuy": 3.68,
            "rateSell": 3.68,
            "currency": "USD"
        },
        "code": "VE",
        "currencyCalc": "VES",
        "currency": "VES",
        "type": "country",
        "send": {
            "minAmount": 20,
            "maxAmount": 5000,
            "targets": [],
            "enabled": false,
            "fee": {}
        }
    },
    // "USDT": {
    //     "collect": {
    //         "minAmount": 25,
    //         "maxAmount": 5000,
    //         "sources": ["ACH","WISE","DEEL","PAYONEER"],
    //         "enabled": false,
    //         "fee":{}
    //     },
    //     "middleAmount": {
    //         "rateBuy": 1,
    //         "rateSell": 1,
    //         "currency": "USD"
    //     },
    //     "code": "USDT",
    //     "currencyCalc": "USD",
    //     "currency": "USDT",
    //     "type": "wallet",
    //     "send": {
    //         "minAmount": 20,
    //         "maxAmount": 5000,
    //         "targets": ["AR","CL","CO","DO","EC","ES","MX","PE","PE-USD","US","VE"],
    //         "enabled": false,
    //         "fee":{}
    //     }
    // },
    "TRAUND": {
        "collect": {
            "minAmount": 25,
            "maxAmount": 5000,
            "sources": ["ACH", "WISE", "DEEL", "PAYONEER"],
            "enabled": true,
            "fee": {}
        },
        "middleAmount": {
            "rateBuy": 1,
            "rateSell": 1,
            "currency": "USD"
        },
        "code": "TRAUND",
        "currencyCalc": "USD",
        "currency": "TRAUND",
        "type": "wallet",
        "send": {
            "minAmount": 20,
            "maxAmount": 5000,
            "targets": ["AR", "CL", "CO", "DO", "EC", "ES", "MX", "PE", "PE-USD", "US", "VE"],
            "enabled": false,
            "fee": {}
        }
    },
    // "WISE": {
    //     "collect": {
    //         "minAmount": 20,
    //         "maxAmount": 5000,
    //         "sources": [],
    //         "enabled": false,
    //         "fee":{}
    //     },
    //     "middleAmount": {
    //         "rateBuy": 1,
    //         "rateSell": 1,
    //         "currency": "USD"
    //     },
    //     "code": "WISE",
    //     "currencyCalc": "USD",
    //     "currency": "WISE",
    //     "type": "wallet",
    //     "send": {
    //         "minAmount": 25.39,
    //         "maxAmount": 5000,
    //         "targets": ["TRAUND"],
    //         "enabled": true,
    //         "fee":{
    //             "type":"A",
    //             "value" : 0.39
    //         }
    //     }
    // },
    // "ACH": {
    //     "collect": {
    //         "minAmount": 20,
    //         "maxAmount": 5000,
    //         "sources": [],
    //         "enabled": false,
    //         "fee":{}
    //     },
    //     "middleAmount": {
    //         "rateBuy": 1,
    //         "rateSell": 1,
    //         "currency": "USD"
    //     },
    //     "code": "ACH",
    //     "currencyCalc": "USD",
    //     "currency": "ACH",
    //     "type": "wallet",
    //     "send": {
    //         "minAmount": 25,
    //         "maxAmount": 5000,
    //         "targets": ["TRAUND"],
    //         "enabled": true,
    //         "fee":{
    //             "type":"A",
    //             "value" : 0
    //         }
    //     }
    // },
    // "DEEL": {
    //     "collect": {
    //         "minAmount": 20,
    //         "maxAmount": 5000,
    //         "sources": [],
    //         "enabled": false,
    //         "fee":{}
    //     },
    //     "middleAmount": {
    //         "rateBuy": 1,
    //         "rateSell": 1,
    //         "currency": "USD"
    //     },
    //     "code": "DEEL",
    //     "currencyCalc": "USD",
    //     "currency": "DEEL",
    //     "type": "wallet",
    //     "send": {
    //         "minAmount": 25,
    //         "maxAmount": 5000,
    //         "targets": ["TRAUND"],
    //         "enabled": true,
    //         "fee":{
    //             "type":"A",
    //             "value" : 0
    //         }
    //     }
    // },
    // "PAYONEER": {
    //     "collect": {
    //         "minAmount": 20,
    //         "maxAmount": 5000,
    //         "sources": [],
    //         "enabled": false,
    //         "fee":{}
    //     },
    //     "middleAmount": {
    //         "rateBuy": 1,
    //         "rateSell": 1,
    //         "currency": "USD"
    //     },
    //     "code": "PAYONEER",
    //     "currencyCalc": "USD",
    //     "currency": "PAYONEER",
    //     "type": "wallet",
    //     "send": {
    //         "minAmount": 25.5,
    //         "maxAmount": 5000,
    //         "targets": ["TRAUND"],
    //         "enabled": true,
    //         "fee":{
    //             "type":"P",
    //             "value" : 0.02
    //         }
    //     }
    // }
}