import React, { useEffect, useState } from 'react';
import './App.css';
import Auth from './components/auth/auth';
import { Container, Button } from "@mantine/core";
import Demo from './components/demo/demo';

function App() {

  const [isLogged, setIsLogged] = useState(false);

  const handleSuccess = () => {
    setIsLogged(true)
  }

  useEffect(() => {
    const token = sessionStorage.getItem("token")
    if(token) setIsLogged(true)
  }, [])

  return (<Container style={{
    width: "100%",
    minHeight: "100vh",
    maxHeight: "100%",
    backgroundImage: `url('/bkg.png')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  }} fluid>
    {!isLogged && <Auth onSuccess={handleSuccess}/>}
    {isLogged && <Demo />}
  </Container>
  );
}

export default App;
