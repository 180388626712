import { Code, Grid } from "@mantine/core"
import { Prism } from "@mantine/prism"

const Response = ({
    response
}: any) => {
    return  <Grid>
    <Grid.Col>
        <Code color="blue">
            RESPONSE
        </Code>
    </Grid.Col>
    <Grid.Col xs={12}>
        <Prism language="json">
            {response != null ? JSON.stringify(response, null, 3) : ``}
        </Prism>
    </Grid.Col>
</Grid> }

export default Response;