import React, { useState } from "react";
import { Grid, Code, Button, Text, List, Box, LoadingOverlay, Alert, Image, Flex } from "@mantine/core";
import { Prism } from '@mantine/prism';
import UploadInputAWS from "./upload";
import Basic from "../../helpers/basicHelper";
import Calculate from "../calculate/calculate";

const API = () => {

    const [step, setStep] = useState(1);
    const [banks, setBanks] = useState([{
        bankName: "",
        bankNumberRoute: "",
        accountId: ""
    }]);
    const [transaction, setTransaction] = useState<any>(null)
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [file, setFile] = useState("");
    const [isUploaded, setIsUploaded] = useState(false)
    const [traunder, setTraunder] = useState(null)
    const [isSuccess, setIsSuccess] = useState(false)
    const [response, setResponse] = useState(null)

    const { CreateTransaction, GetStatus, RegisterDeposit } = Basic()

    const handleCreateTransaction = async () => {
        setIsLoading(true)
        const { response, traunder, success } = await CreateTransaction();

        if (success) {
            setTraunder(traunder)
            setTransaction(response)

            let { data, success } = await GetStatus({ transactionId: response.id });
            if (success) {
                setResponse(data)
            }

            setIsSuccess(true)
        }
        else {
            setMessage(response?.message)
        }
        setIsLoading(false)
    }

    const handlePay = async () => {
        setIsLoading(true)

        const { url, success } = await RegisterDeposit(transaction.id, file);

        if (success) {

            const { data, success } = await GetStatus({ transactionId: transaction.id });

            if (success) {
                setResponse(data)
            }
        }

        setIsLoading(false)
    }

    const onSuccessUpload = (url: string) => {
        setFile(url)
        setIsUploaded(true)
    }

    const StartTransaction = () => <Grid justify="center">
        <Grid.Col xs={7}>
            <Grid>
                {!isSuccess ? <Grid.Col xs={12}>
                    <Alert color="blue">
                        <Text fw={800}>URL</Text>
                        <Code color="blue">
                            https://sandbox.traund.com/transaction/create
                        </Code>
                    </Alert>
                    <Prism language="json">
                        {
                            `{
        "receiver": 
        {
            "firstName": "Heracles",
            "lastName": "Mythos",
            "email": "jrrb.dev@gmail.com",
            "bankName": "Bank of Country",
            "cellphone": "51998712345",
            "bankNumber": "181674000566487648",
            "bankNumberRoute": "1912000003000813903"
        },
        "storeId": "e3e3sddasdasd"
    }`
                        }
                    </Prism>
                </Grid.Col> :
                    <Response />}
                <Grid.Col xs={12}>
                    {message?.length > 0 && <Alert color="yellow">
                        {message}
                    </Alert>}
                </Grid.Col>
                <Grid.Col xs={12}>
                    <Button onClick={handleCreateTransaction} fullWidth mt={"xl"}>CREATE TRANSACTION</Button>
                </Grid.Col>
                {
                    isSuccess && <Grid.Col xs={12}>
                        <Button color="teal" onClick={() => setStep(3)} fullWidth>NEXT</Button>
                    </Grid.Col>
                }
            </Grid>
        </Grid.Col>
    </Grid>

    const Response = () => <Grid.Col xs={12}>
        <Grid>
            <Grid.Col xs={12}>
                <Alert color="teal">
                    <Text fw={800}>RESPONSE</Text>
                    <Code color="teal">
                        https://sandbox.traund.com/transaction/create
                    </Code>
                </Alert>
            </Grid.Col>
        </Grid>
        <Grid.Col xs={12}>
            <Prism language="json">
                {
                    JSON.stringify(transaction, null, 3)
                }
            </Prism>
        </Grid.Col>
    </Grid.Col>

    const TraunderInformation = ({ traunder }: any) => <Grid justify="center" mt={"xl"}>

        <Grid.Col xs={4}>
            {
                response == null && <Grid>
                    <Grid.Col xs={12} bg={"#f8f8f8"} >
                        <Text align="center" color="blue" fw={600} fz={24}>{traunder.traunderName}</Text>
                    </Grid.Col>
                    <Grid.Col xs={12} bg={"#f8f8f8"} mt={15}>
                        <List>
                            {traunder?.bankAccounts?.map((b: any, i: number) => {
                                return (
                                    <List
                                        key={b.bankNumberRoute + b.bankName + i}
                                        listStyleType="none">
                                        <List.Item >
                                            <Grid>
                                                <Grid.Col xs={5}>
                                                    <Text color={"blue"} variant="text" align="center" fz={18} fw={600}>
                                                        {b.bankName}
                                                    </Text>
                                                </Grid.Col>
                                                <Grid.Col xs={7}>
                                                    <Text variant="text" style={{ fontWeight: 600, marginBottom: 5 }}>
                                                        <Text
                                                            color={"gray"}
                                                            fz={12}
                                                            fw={400}>

                                                            {transaction?.countryFrom == "US"
                                                                ? "ACCOUNT ID"
                                                                : "BANK/ENTITY NAME"}
                                                        </Text>
                                                        <Text
                                                            fw={600}>
                                                            {b?.accountId}
                                                        </Text>
                                                    </Text>
                                                    <Text variant="body1">


                                                        {transaction?.countryFrom == "US"
                                                            ? null
                                                            : transaction?.countryFrom == "PE" || transaction?.countryFrom == "VE"
                                                                ? "GLOBAL ACCOUNT"
                                                                : transaction?.countryFrom == "MX"
                                                                    ? "CLABE ACCOUNT"
                                                                    : null}{" "}
                                                        {" "}
                                                        {b.bankNumberRoute}
                                                    </Text>
                                                </Grid.Col>
                                            </Grid>
                                        </List.Item>
                                    </List>


                                );
                            })}
                        </List>
                    </Grid.Col>
                    <Grid.Col xs={12} mt={15}>
                        {!isUploaded && <UploadInputAWS transactionId={transaction?.id} onSuccess={onSuccessUpload} />}
                        {isUploaded &&
                            <Grid>
                                <Grid.Col xs={12}>
                                    <Alert w={"100%"} mt={"md"} mb={"md"} color="green">VOUCHER UPLOADED</Alert>
                                </Grid.Col>
                            </Grid>
                        }
                    </Grid.Col>
                    <Grid.Col xs={12}>
                        <Button disabled={!isUploaded} onClick={handlePay} fullWidth>
                            PAY
                        </Button>
                    </Grid.Col>

                </Grid>
            }
            {
                response != null && <Grid>
                    <Grid.Col xs={12}>
                        <Grid>
                            <Grid.Col xs={12}>
                                <Alert color="teal">
                                    <Text fw={800}>TRANSACTION STATUS</Text>
                                    <Code color="teal">
                                        https://sandbox.traund.com/transaction/status
                                    </Code>
                                </Alert>
                            </Grid.Col>
                        </Grid>
                        <Prism language="json">
                            {JSON.stringify(response, null, 3)}
                        </Prism>
                    </Grid.Col>
                </Grid>
            }
        </Grid.Col>
    </Grid>;

    return <Box pos="relative">
        {step === 1 && <Calculate onSubmit={() => setStep(2)} />}
        {step === 2 && <StartTransaction />}
        {step === 3 && <TraunderInformation traunder={traunder} />}
        <LoadingOverlay visible={isLoading} overlayBlur={2} />
    </Box>
}

export default API;