import { Grid } from "@mantine/core";
import Calculate from "../calculate/calculate";
import { useState } from "react";
import Selector from "../selector/selector";

const Demo = () => {

    //const [afterCalculate, setAfterCalculated] = useState(false)

    return <Grid justify="center" h={"100%"}>
        <Grid.Col xs={12}>
             <Selector />
        </Grid.Col>
    </Grid>
}

export default Demo;