import { Grid, TextInput, Button } from "@mantine/core";
import { useForm } from '@mantine/form';

const Form = ({ onSubmit } : any) => {

    const form = useForm({
        initialValues: {
            apiKey: "",
            apiSecret: ""
        }
    });

    const handleSubmit = () => {
        onSubmit(form.values)
    }

    return <Grid>
         <Grid.Col>
            <TextInput
                placeholder="KEY-VALUE"
                label="API-KEY"
                {...form.getInputProps('apiKey')}
                withAsterisk
            />
        </Grid.Col>
        <Grid.Col>
            <TextInput
                placeholder="SECRET-VALUE"
                label="API-SECRET"
                {...form.getInputProps('apiSecret')}
                withAsterisk
            />
        </Grid.Col>
        <Grid.Col>
            <Button size="md" variant="filled" onClick={handleSubmit} fullWidth>
                ACCEDER
            </Button>
        </Grid.Col>
    </Grid>
}

export default Form;