export const countries = [
  { code: "AD", label: "Andorra", prefix: "+376" },
  { code: "AE", label: "United Arab Emirates", prefix: "+971" },
  { code: "AF", label: "Afghanistan", prefix: "+93" },
  { code: "AG", label: "Antigua and Barbuda", prefix: "+1-268" },
  { code: "AI", label: "Anguilla", prefix: "+1-264" },
  { code: "AL", label: "Albania", prefix: "+355" },
  { code: "AM", label: "Armenia", prefix: "+374" },
  { code: "AO", label: "Angola", prefix: "+244" },
  { code: "AQ", label: "Antarctica", prefix: "+672" },
  { code: "AR", label: "Argentina", prefix: "+54", currency: "ARS" },
  { code: "AS", label: "American Samoa", prefix: "+1-684" },
  { code: "AT", label: "Austria", prefix: "+43" },
  { code: "AU", label: "Australia", prefix: "+61" },
  { code: "AW", label: "Aruba", prefix: "+297" },
  { code: "AX", label: "Alland Islands", prefix: "+358" },
  { code: "AZ", label: "Azerbaijan", prefix: "+994" },
  { code: "BA", label: "Bosnia and Herzegovina", prefix: "+387" },
  { code: "BB", label: "Barbados", prefix: "+1-246" },
  { code: "BD", label: "Bangladesh", prefix: "+880" },
  { code: "BE", label: "Belgium", prefix: "+32" },
  { code: "BF", label: "Burkina Faso", prefix: "+226" },
  { code: "BG", label: "Bulgaria", prefix: "+359" },
  { code: "BH", label: "Bahrain", prefix: "+973" },
  { code: "BI", label: "Burundi", prefix: "+257" },
  { code: "BJ", label: "Benin", prefix: "+229" },
  { code: "BL", label: "Saint Barthelemy", prefix: "+590" },
  { code: "BM", label: "Bermuda", prefix: "+1-441" },
  { code: "BN", label: "Brunei Darussalam", prefix: "+673" },
  { code: "BO", label: "Bolivia", prefix: "+591" },
  { code: "BR", label: "Brasil", prefix: "+55", currency: "BRL" },
  { code: "BS", label: "Bahamas", prefix: "+1-242" },
  { code: "BT", label: "Bhutan", prefix: "+975" },
  { code: "BV", label: "Bouvet Island", prefix: "+47" },
  { code: "BW", label: "Botswana", prefix: "+267" },
  { code: "BY", label: "Belarus", prefix: "+375" },
  { code: "BZ", label: "Belize", prefix: "+501" },
  { code: "CA", label: "Canada", prefix: "+1-CA" },
  { code: "CC", label: "Cocos (Keeling) Islands", prefix: "+61" },
  { code: "CD", label: "Congo, Democratic Republic of the", prefix: "+243" },
  { code: "CF", label: "Central African Republic", prefix: "+236" },
  { code: "CG", label: "Congo, Republic of the", prefix: "+242" },
  { code: "CH", label: "Switzerland", prefix: "+41" },
  { code: "CI", label: "Cote d'Ivoire", prefix: "+225" },
  { code: "CK", label: "Cook Islands", prefix: "+682" },
  { code: "CL", label: "Chile", prefix: "+56", currency: "CLP" },
  { code: "CM", label: "Cameroon", prefix: "+237" },
  { code: "CN", label: "China", prefix: "+86" },
  { code: "CO", label: "Colombia", prefix: "+57", currency: "COP" },
  { code: "CR", label: "Costa Rica", prefix: "+506", currency: "CRC" },
  { code: "CU", label: "Cuba", prefix: "+53" },
  { code: "CV", label: "Cape Verde", prefix: "+238" },
  { code: "CW", label: "Curacao", prefix: "+599" },
  { code: "CX", label: "Christmas Island", prefix: "+61" },
  { code: "CY", label: "Cyprus", prefix: "+357" },
  { code: "CZ", label: "Czech Republic", prefix: "+420" },
  { code: "DE", label: "Germany", prefix: "+49" },
  { code: "DJ", label: "Djibouti", prefix: "+253" },
  { code: "DK", label: "Denmark", prefix: "+45" },
  { code: "DM", label: "Dominica", prefix: "+1-767" },
  {
    code: "DO",
    label: "Dominican Republic",
    prefix: "+1-809",
    currency: "DOP",
  },
  { code: "DZ", label: "Algeria", prefix: "+213" },
  { code: "EC", label: "Ecuador", prefix: "+593", currency: "USD" },
  { code: "EE", label: "Estonia", prefix: "+372" },
  { code: "EG", label: "Egypt", prefix: "+20" },
  { code: "EH", label: "Western Sahara", prefix: "+212" },
  { code: "ER", label: "Eritrea", prefix: "+291" },
  { code: "ES", label: "Spain", prefix: "+34", currency: "EUR" },
  { code: "ET", label: "Ethiopia", prefix: "+251" },
  { code: "FI", label: "Finland", prefix: "+358" },
  { code: "FJ", label: "Fiji", prefix: "+679" },
  { code: "FK", label: "Falkland Islands (Malvinas)", prefix: "+500" },
  { code: "FM", label: "Micronesia, Federated States of", prefix: "+691" },
  { code: "FO", label: "Faroe Islands", prefix: "+298" },
  { code: "FR", label: "France", prefix: "+33" },
  { code: "GA", label: "Gabon", prefix: "+241" },
  { code: "GB", label: "United Kingdom", prefix: "+44" },
  { code: "GD", label: "Grenada", prefix: "+1-473" },
  { code: "GE", label: "Georgia", prefix: "+995" },
  { code: "GF", label: "French Guiana", prefix: "+594" },
  { code: "GG", label: "Guernsey", prefix: "+44" },
  { code: "GH", label: "Ghana", prefix: "+233" },
  { code: "GI", label: "Gibraltar", prefix: "+350" },
  { code: "GL", label: "Greenland", prefix: "+299" },
  { code: "GM", label: "Gambia", prefix: "+220" },
  { code: "GN", label: "Guinea", prefix: "+224" },
  { code: "GP", label: "Guadeloupe", prefix: "+590" },
  { code: "GQ", label: "Equatorial Guinea", prefix: "+240" },
  { code: "GR", label: "Greece", prefix: "+30" },
  {
    code: "GS",
    label: "South Georgia and the South Sandwich Islands",
    prefix: "+500",
  },
  { code: "GT", label: "Guatemala", prefix: "+502" },
  { code: "GU", label: "Guam", prefix: "+1-671" },
  { code: "GW", label: "Guinea-Bissau", prefix: "+245" },
  { code: "GY", label: "Guyana", prefix: "+592" },
  { code: "HK", label: "Hong Kong", prefix: "+852" },
  { code: "HM", label: "Heard Island and McDonald Islands", prefix: "+672" },
  { code: "HN", label: "Honduras", prefix: "+504" },
  { code: "HR", label: "Croatia", prefix: "+385" },
  { code: "HT", label: "Haiti", prefix: "+509" },
  { code: "HU", label: "Hungary", prefix: "+36" },
  { code: "ID", label: "Indonesia", prefix: "+62" },
  { code: "IE", label: "Ireland", prefix: "+353" },
  { code: "IL", label: "Israel", prefix: "+972" },
  { code: "IM", label: "Isle of Man", prefix: "+44" },
  { code: "IN", label: "India", prefix: "+91" },
  { code: "IO", label: "British Indian Ocean Territory", prefix: "+246" },
  { code: "IQ", label: "Iraq", prefix: "+964" },
  { code: "IR", label: "Iran, Islamic Republic of", prefix: "+98" },
  { code: "IS", label: "Iceland", prefix: "+354" },
  { code: "IT", label: "Italy", prefix: "+39" },
  { code: "JE", label: "Jersey", prefix: "+44" },
  { code: "JM", label: "Jamaica", prefix: "+1-876" },
  { code: "JO", label: "Jordan", prefix: "+962" },
  { code: "JP", label: "Japan", prefix: "+81" },
  { code: "KE", label: "Kenya", prefix: "+254" },
  { code: "KG", label: "Kyrgyzstan", prefix: "+996" },
  { code: "KH", label: "Cambodia", prefix: "+855" },
  { code: "KI", label: "Kiribati", prefix: "+686" },
  { code: "KM", label: "Comoros", prefix: "+269" },
  { code: "KN", label: "Saint Kitts and Nevis", prefix: "+1-869" },
  {
    code: "KP",
    label: "Korea, Democratic People's Republic of",
    prefix: "+850",
  },
  { code: "KR", label: "Korea, Republic of", prefix: "+82" },
  { code: "KW", label: "Kuwait", prefix: "+965" },
  { code: "KY", label: "Cayman Islands", prefix: "+1-345" },
  { code: "KZ", label: "Kazakhstan", prefix: "+7" },
  { code: "LA", label: "Lao People's Democratic Republic", prefix: "+856" },
  { code: "LB", label: "Lebanon", prefix: "+961" },
  { code: "LC", label: "Saint Lucia", prefix: "+1-758" },
  { code: "LI", label: "Liechtenstein", prefix: "+423" },
  { code: "LK", label: "Sri Lanka", prefix: "+94" },
  { code: "LR", label: "Liberia", prefix: "+231" },
  { code: "LS", label: "Lesotho", prefix: "+266" },
  { code: "LT", label: "Lithuania", prefix: "+370" },
  { code: "LU", label: "Luxembourg", prefix: "+352" },
  { code: "LV", label: "Latvia", prefix: "+371" },
  { code: "LY", label: "Libya", prefix: "+218" },
  { code: "MA", label: "Morocco", prefix: "+212" },
  { code: "MC", label: "Monaco", prefix: "+377" },
  { code: "MD", label: "Moldova, Republic of", prefix: "+373" },
  { code: "ME", label: "Montenegro", prefix: "+382" },
  { code: "MF", label: "Saint Martin (French part)", prefix: "+590" },
  { code: "MG", label: "Madagascar", prefix: "+261" },
  { code: "MH", label: "Marshall Islands", prefix: "+692" },
  {
    code: "MK",
    label: "Macedonia, the Former Yugoslav Republic of",
    prefix: "+389",
  },
  { code: "ML", label: "Mali", prefix: "+223" },
  { code: "MM", label: "Myanmar", prefix: "+95" },
  { code: "MN", label: "Mongolia", prefix: "+976" },
  { code: "MO", label: "Macao", prefix: "+853" },
  { code: "MP", label: "Northern Mariana Islands", prefix: "+1-670" },
  { code: "MQ", label: "Martinique", prefix: "+596" },
  { code: "MR", label: "Mauritania", prefix: "+222" },
  { code: "MS", label: "Montserrat", prefix: "+1-664" },
  { code: "MT", label: "Malta", prefix: "+356" },
  { code: "MU", label: "Mauritius", prefix: "+230" },
  { code: "MV", label: "Maldives", prefix: "+960" },
  { code: "MW", label: "Malawi", prefix: "+265" },
  { code: "MX", label: "Mexico", prefix: "+52", currency: "MXN" },
  { code: "MY", label: "Malaysia", prefix: "+60" },
  { code: "MZ", label: "Mozambique", prefix: "+258" },
  { code: "NA", label: "Namibia", prefix: "+264" },
  { code: "NC", label: "New Caledonia", prefix: "+687" },
  { code: "NE", label: "Niger", prefix: "+227" },
  { code: "NF", label: "Norfolk Island", prefix: "+672" },
  { code: "NG", label: "Nigeria", prefix: "+234" },
  { code: "NI", label: "Nicaragua", prefix: "+505" },
  { code: "NL", label: "Netherlands", prefix: "+31" },
  { code: "NO", label: "Norway", prefix: "+47" },
  { code: "NP", label: "Nepal", prefix: "+977" },
  { code: "NR", label: "Nauru", prefix: "+674" },
  { code: "NU", label: "Niue", prefix: "+683" },
  { code: "NZ", label: "New Zealand", prefix: "+64" },
  { code: "OM", label: "Oman", prefix: "+968" },
  { code: "PA", label: "Panama", prefix: "+507" },
  { code: "PE", label: "Peru", prefix: "+51", currency: "PEN" },
  { code: "PE-USD", label: "Peru", prefix: "+51", currency: "USD", secondary: true },
  { code: "PF", label: "French Polynesia", prefix: "+689" },
  { code: "PG", label: "Papua New Guinea", prefix: "+675" },
  { code: "PH", label: "Philippines", prefix: "+63" },
  { code: "PK", label: "Pakistan", prefix: "+92" },
  { code: "PL", label: "Poland", prefix: "+48" },
  { code: "PM", label: "Saint Pierre and Miquelon", prefix: "+508" },
  { code: "PN", label: "Pitcairn", prefix: "+870" },
  { code: "PR", label: "Puerto Rico", prefix: "+1-PR" },
  { code: "PS", label: "Palestine, State of", prefix: "+970" },
  { code: "PT", label: "Portugal", prefix: "+351" },
  { code: "PW", label: "Palau", prefix: "+680" },
  { code: "PY", label: "Paraguay", prefix: "+595", currency: "PYG" },
  { code: "QA", label: "Qatar", prefix: "+974" },
  { code: "RE", label: "Reunion", prefix: "+262" },
  { code: "RO", label: "Romania", prefix: "+40" },
  { code: "RS", label: "Serbia", prefix: "+381" },
  { code: "RU", label: "Russian Federation", prefix: "+7" },
  { code: "RW", label: "Rwanda", prefix: "+250" },
  { code: "SA", label: "Saudi Arabia", prefix: "+966" },
  { code: "SB", label: "Solomon Islands", prefix: "+677" },
  { code: "SC", label: "Seychelles", prefix: "+248" },
  { code: "SD", label: "Sudan", prefix: "+249" },
  { code: "SE", label: "Sweden", prefix: "+46" },
  { code: "SG", label: "Singapore", prefix: "+65" },
  { code: "SH", label: "Saint Helena", prefix: "+290" },
  { code: "SI", label: "Slovenia", prefix: "+386" },
  { code: "SJ", label: "Svalbard and Jan Mayen", prefix: "+47" },
  { code: "SK", label: "Slovakia", prefix: "+421" },
  { code: "SL", label: "Sierra Leone", prefix: "+232" },
  { code: "SM", label: "San Marino", prefix: "+378" },
  { code: "SN", label: "Senegal", prefix: "+221" },
  { code: "SO", label: "Somalia", prefix: "+252" },
  { code: "SR", label: "Suriname", prefix: "+597" },
  { code: "SS", label: "South Sudan", prefix: "+211" },
  { code: "ST", label: "Sao Tome and Principe", prefix: "+239" },
  { code: "SV", label: "El Salvador", prefix: "+503", currency: "BTC" },
  { code: "SX", label: "Sint Maarten (Dutch part)", prefix: "+1-721" },
  { code: "SY", label: "Syrian Arab Republic", prefix: "+963" },
  { code: "SZ", label: "Swaziland", prefix: "+268" },
  { code: "TC", label: "Turks and Caicos Islands", prefix: "+1-649" },
  { code: "TD", label: "Chad", prefix: "+235" },
  { code: "TF", label: "French Southern Territories", prefix: "+262" },
  { code: "TG", label: "Togo", prefix: "+228" },
  { code: "TH", label: "Thailand", prefix: "+66" },
  { code: "TJ", label: "Tajikistan", prefix: "+992" },
  { code: "TK", label: "Tokelau", prefix: "+690" },
  { code: "TL", label: "Timor-Leste", prefix: "+670" },
  { code: "TM", label: "Turkmenistan", prefix: "+993" },
  { code: "TN", label: "Tunisia", prefix: "+216" },
  { code: "TO", label: "Tonga", prefix: "+676" },
  { code: "TR", label: "Turkey", prefix: "+90" },
  { code: "TRAUND", label: "Billetera Traund", prefix: "+1", currency: "USD" },
  { code: "TT", label: "Trinidad and Tobago", prefix: "+1-868" },
  { code: "TV", label: "Tuvalu", prefix: "+688" },
  { code: "TW", label: "Taiwan, Province of China", prefix: "+886" },
  { code: "TZ", label: "United Republic of Tanzania", prefix: "+255" },
  { code: "UA", label: "Ukraine", prefix: "+380" },
  { code: "UG", label: "Uganda", prefix: "+256" },
  { code: "US", label: "United States", prefix: "+1", currency: "USD" },
  { code: "UY", label: "Uruguay", prefix: "+598" },
  { code: "UZ", label: "Uzbekistan", prefix: "+998" },
  { code: "VA", label: "Holy See (Vatican City State)", prefix: "+379" },
  { code: "VC", label: "Saint Vincent and the Grenadines", prefix: "+1-784" },
  { code: "VE", label: "Venezuela", prefix: "+58", currency: "VES" },
  { code: "VG", label: "British Virgin Islands", prefix: "+1-284" },
  { code: "VI", label: "US Virgin Islands", prefix: "+1-340" },
  { code: "VN", label: "Vietnam", prefix: "+84" },
  { code: "VU", label: "Vanuatu", prefix: "+678" },
  { code: "WF", label: "Wallis and Futuna", prefix: "+681" },
  { code: "WS", label: "Samoa", prefix: "+685" },
  { code: "XK", label: "Kosovo", prefix: "+383" },
  { code: "YE", label: "Yemen", prefix: "+967" },
  { code: "YT", label: "Mayotte", prefix: "+262" },
  { code: "ZA", label: "South Africa", prefix: "+27" },
  { code: "ZM", label: "Zambia", prefix: "+260" },
  { code: "ZW", label: "Zimbabwe", prefix: "+263" },
];
