import { useEffect, useState } from "react";
import { CalculatorCountries } from "../data/calculatorCountries"
import { countries } from "../data/countries";
const useCalculatorCountries = (country: string = "") => {

    // const [countriesForCalculator, setCountriesForCalculator] = useState<string[]>([]);
    const [targets, setTargets] = useState<string[]>([]);
    const [sources, setSources] = useState<any[]>([]);

    const getCountriesForSelect = (countries: any, countriesInfo: any) => {

        return countries.map((e: any) => {
            const foundCountry = countriesInfo.find((c: any) => c.code == e)

            return {
                label: `${foundCountry?.label} (${foundCountry?.currency})`,
                value: e
            }
        })
    }

    useEffect(() => {

        let countriesForCalculator = Object.keys(CalculatorCountries);
        let countriesArray = countriesForCalculator.map(e => (CalculatorCountries as any)[e]);
        let filteredCountries = countriesArray.filter((e: any) => e?.send?.enabled);

        if (country == "") {
            setSources(getCountriesForSelect(filteredCountries.map(e => e.code), countries));
            setTargets(getCountriesForSelect(countriesArray.map(e => e.code), countries));
            console.info("Empty country")
            return;
        }

        if (country.length > 0) {
            // setSources(getCountriesForSelect((CalculatorCountries as any)[country]?.collect?.sources, countries))
            setTargets(getCountriesForSelect((CalculatorCountries as any)[country]?.send?.targets, countries))
        }

    }, [country])

    return {
        // countriesForCalculator,
        targets,
        sources
    }
}

export default useCalculatorCountries;