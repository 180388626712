import React, { useState, useEffect } from "react";
import { Grid, Text, Button, Select, Flex, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { countries } from "../../data/countries";
import useCalculatorCountries from "../../hook/useCalculatorCountries";

const SDK = () => {

    const callbackFn = (response: any, param: any) => {
        setCompleted(true);
        console.log(`Response SDK: ${JSON.stringify(response)}`);
        console.log(`Param SDK: ${param}`);
    }

    const [completed, setCompleted] = useState<boolean>(false);
    const [value, setValue] = useState<string | null>("regular_pay");
    const [paymentMethod, setPaymentMethod] = useState<string | null>("TRANSFER");

    const form = useForm({
        initialValues: {
            amountFrom: "0",
            countryFrom: "",
            amountTo: "0",
            countryTo: "",
        }
    });

    const { sources, targets } = useCalculatorCountries(form.getInputProps('countryFrom').value)

    const handleStart = () => {
        switch (value) {
            case "regular_pay":
                handleRegularPay();
                break;

            case "regular_pay_metadata":
                handleRegularPayWithMetadata();
                break;

            case "direct_pay":
                handleDirectPay();
                break;
        }

        // if (value == "asGuestNoInfo") {
        //     handleRegularPay()
        // }
        // if (value == "asGuestInfo") {
        //     handleRegularPayWithMetadata()
        // }
        // else {
        //     handleDirectPay()
        // }
    }

    const handleDirectPay = () => {
        const token = sessionStorage.getItem("token");
        //const storeId = sessionStorage.getItem("storeId");

        const app = new (window as any).TraundApp();

        app.init({
            sdkMode: 'DIRECT_PAY',
            paymentMethods: [paymentMethod],
            request_data: {
                storeId: '',
                amount: parseFloat(form.getInputProps("amountFrom").value || ""),
                country: getCountryByCode(form.getInputProps("countryFrom").value)?.code,
                currency: getCountryByCode(form.getInputProps("countryFrom").value)?.currency,
                appliedFee: true
            },
            metadata: {
                sender: {
                    email: 'test@traund.com',
                    firstName: 'Test',
                    lastName: 'Traund',
                    identificationCountry: 'PE',
                    identificationType: 'ID_CARD',
                    identificationNumber: '44444444',
                    phonePrefix: '+51',
                    phoneNumber: '987654321',
                },
                receivers: []
            },
            language: 'en',
            theme: 'light',
            isSandbox: true,
            sandboxMode: process.env.REACT_APP_ENV,
            token: token
        }, callbackFn, ['text']);
    }

    const handleRegularPayWithMetadata = () => {

        const token = sessionStorage.getItem("token");
        //const storeId = sessionStorage.getItem("storeId");

        const app = new (window as any).TraundApp();

        app.init({
            sdkMode: 'REGULAR_PAY',
            paymentMethods: [paymentMethod],
            request_data: {
                storeId: '',
                amount: parseFloat(form.getInputProps("amountFrom").value || ""),
                country: getCountryByCode(form.getInputProps("countryFrom").value)?.code,
                currency: getCountryByCode(form.getInputProps("countryFrom").value)?.currency,
                appliedFee: true
            },
            metadata: {
                sender: {
                    email: 'test@traund.com',
                    firstName: 'Test',
                    lastName: 'Traund',
                    identificationCountry: 'PE',
                    identificationType: 'ID_CARD',
                    identificationNumber: '44444444',
                    phonePrefix: '+51',
                    phoneNumber: '987654321',
                },
                receivers: []
            },
            language: 'en',
            theme: 'light',
            isSandbox: true,
            sandboxMode: process.env.REACT_APP_ENV,
            token: token
        }, callbackFn, ['text']);
    }

    const handleRegularPay = () => {

        const token = sessionStorage.getItem("token");
        // const storeId = sessionStorage.getItem("storeId");

        const app = new (window as any).TraundApp();

        app.init({
            sdkMode: 'REGULAR_PAY',
            paymentMethods: [paymentMethod],
            request_data: {
                storeId: '',
                amount: parseFloat(form.getInputProps("amountFrom").value || ""),
                country: getCountryByCode(form.getInputProps("countryFrom").value)?.code,
                currency: getCountryByCode(form.getInputProps("countryFrom").value)?.currency,
                appliedFee: true
            },
            language: 'en',
            theme: 'light',
            isSandbox: true,
            sandboxMode: process.env.REACT_APP_ENV,
            token: token
        }, callbackFn, ['text']);
    }

    const getCountryByCode = (search: string) => {
        return countries.find(e => e.code?.toLowerCase() === search?.toLowerCase());
    }

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://unpkg.com/@traund/sdk/build/sdk.bundle.js";
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }

    }, [])


    return <Grid justify="center">
        <Grid.Col xs={4}>
            <Grid>
                <Grid.Col xs={12}>
                    <Text align="center" fw={"600"} fz={"lg"} mb={"lg"} mt={"lg"}>PAYMENT WITH TRAUND SDK</Text>
                    <Select value={value} onChange={setValue} data={[
                        {
                            value: "regular_pay",
                            label: "REGULAR_PAY"
                        },
                        {
                            value: "regular_pay_metadata",
                            label: "REGULAR_PAY WITH METADATA"
                        },
                        {
                            value: "direct_pay",
                            label: "DIRECT PAY"
                        }
                    ]} />
                </Grid.Col>
                <Grid.Col xs={12}>
                    <Select value={paymentMethod} onChange={setPaymentMethod} data={[
                        {
                            value: "TRANSFER",
                            label: "TRANSFER"
                        },
                        {
                            value: "CARD",
                            label: "CARD"
                        }
                    ]} />
                </Grid.Col>
                <Grid.Col xs={12}>
                    <Grid>
                        <Grid.Col xs={12}>
                            <Select
                                label="Country From"
                                placeholder="Country"
                                {...form.getInputProps('countryFrom')}
                                data={sources} />
                        </Grid.Col>
                        <Grid.Col xs={12}>
                            <TextInput
                                placeholder="100.00"
                                label="Amount"
                                {...form.getInputProps('amountFrom')}
                                withAsterisk

                            />
                        </Grid.Col>
                    </Grid>
                </Grid.Col>
                <Grid.Col xs={12}>
                    <Button radius={"sm"} mt={"xl"} mb={"xl"} size="md" color="blue" onClick={handleStart} disabled={completed} fullWidth>START SDK</Button>
                </Grid.Col>
                <Grid.Col xs={12} bg={"#d7d7d722"} h={100}>
                    <Flex justify="center" align="center">
                        <div id="traund-start-button"></div>
                    </Flex>
                </Grid.Col>
            </Grid>
        </Grid.Col>
    </Grid>
}

export default SDK;