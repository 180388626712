import basicService from "../services/basicService";

const Basic = () => {

    const Authorize = async (props: any) => {

        try {

            const { apiKey, apiSecret } = props;

            const model = {
                apiKey,
                apiSecret
            };

            const { data } = await basicService.PostAuthorize(model);

            return {
                success: data.success,
                sessionRequestId: data.data.sessionRequestId
            }

        } catch (e) {
            return {
                success: false,
                sessionRequestId: ""
            }
        }
    }

    const SessionStart = async (props: any) => {

        try {

            const { sessionRequestId, activityId, options } = props;

            const model = {
                sessionRequestId,
                activityId,
                options
            };

            const { data } = await basicService.PostSessionStart(model);

            return {
                success: data.success,
                token: data.data.token
            }

        } catch (e) {
            return {
                success: false,
                token: ""
            }
        }
    }

    const Calculate = async (props: any) => {

        try {

            const { amountFrom, countryFrom, countryTo, currencyFrom, currencyTo } = props;

            const model = {
                "source": "from",
                "from": {
                    "amount": +amountFrom,
                    "country": countryFrom?.toUpperCase(),
                    "currency": currencyFrom?.toUpperCase()
                },
                "to": {
                    "amount": "",
                    "country": countryTo?.toUpperCase(),
                    "currency": currencyTo?.toUpperCase()
                }
            }

            const token = sessionStorage.getItem("token") || ""

            const { data } = await basicService.PostCalculate(model, token);

            return {
                success: data.success,
                calculated: {
                    amount: 0,
                    country: "",
                    currency: "",
                    storeId: "",
                    ...data.data
                },
                response: data
            }

        } catch (e) {
            return {
                success: false,
                calculated: {
                    amount: 0,
                    country: "",
                    currency: "",
                    storeId: "",
                },
                response: null
            }
        }
    }


    const CreateTransaction = async () => {

        try {

            let model = {
                "receiver": {
                    "firstName": "Heracles",
                    "lastName": "Mythos",
                    "email": "jrrb.dev@gmail.com",
                    "bankName": "Bank of country",
                    "cellphone": "51998712345",
                    "bankNumber": "181674000566487648",
                    "bankNumberRoute": "1912000003000813903"
                },
                "storeId": sessionStorage.getItem("storeId"),
            }

            const token = sessionStorage.getItem("token") || ""

            const { data } = await basicService.PostCreateTransaction(model, token);

            return {
                success: data.success,
                response: {
                    amountTo: 0,
                    id: "",
                    "receiver-email": "",
                    statusCode: 0,
                    statusName: "",
                    _paymentId: "",
                    message: data.message || "",
                    ...data.data
                },
                traunder: {
                    traunderName: "",
                    bankAccounts: [],
                    ...data.data?.traunder
                }
            }

        } catch (e: any) {

            return {
                success: false,
                response: {
                    message: "Error response"
                },
                traunder: null
            }
        }
    }

    const RegisterDeposit = async (transactionId: string, fd: any) => {

        try {
            const token = sessionStorage.getItem("token") || ""

            const name = `tr_demo_ui_app_${transactionId}_origin_voucher.jpeg`;

            let imageFormData = new FormData();
            let renamedImage = new File([fd], name, {
                type: "image/jpeg"
            });

            imageFormData.append("voucher", renamedImage);

            const { data } = await basicService.PostRegisterDeposit(transactionId, imageFormData, token);

            return {
                success: data.success,
                url: ""
            }

        } catch (e) {
            return {
                success: false,
                url: ""
            }
        }
    }


    const UpdateStatusTransaction = async (props: any) => {

        try {

            const { amountFrom, countryFrom, countryTo, currencyTo, currencyFrom } = props;

            const model = {
                "source": "from",
                "from": {
                    "amount": amountFrom,
                    "country": countryFrom,
                    "currency": currencyFrom
                },
                "to": {
                    "amount": "",
                    "country": countryTo,
                    "currency": currencyTo
                }
            }

            const token = sessionStorage.getItem("token") || ""

            const { data } = await basicService.PostCreateTransaction(model, token);

            return {
                success: data.success,
                calculated: {}
            }

        } catch (e) {
            return {
                success: false,
                calculated: {}
            }
        }
    }

    const GetStatus = async (props: any) => {
        try {
            const { transactionId } = props;
            const token = sessionStorage.getItem("token") || ""
            const { data } = await basicService.GetStatus(transactionId, token);

            return {
                success: data.success,
                data: data.data
            }

        } catch (e) {
            return {
                success: false,
                data: null
            }
        }
    }

    return {
        Authorize,
        SessionStart,
        Calculate,
        CreateTransaction,
        RegisterDeposit,
        UpdateStatusTransaction,
        GetStatus
    }
}

export default Basic;