import { Code, Grid } from "@mantine/core"
import { Prism } from "@mantine/prism"

const Payload = ({
    amount,
    countryFrom,
    currencyFrom,
    countryTo,
    currencyTo
}: any) => {
    return  <Grid>
    <Grid.Col>
        <Code color="teal">
            PAYLOAD
        </Code>
    </Grid.Col>
    <Grid.Col xs={12}>
        <Prism c={"teal"} language="json">{
`{
    "source": "from",
    "from": {
        "amount": ${amount},
        "country":  ${countryFrom},
        "currency":  ${currencyFrom}
    },
    "to": {
        "country":  ${countryTo},
        "currency":  ${currencyTo}
    }
}`
}
        </Prism>
    </Grid.Col>
</Grid>
}

export default Payload;