import { useEffect, useState } from "react"
import { Api, ApplicationOne, ArrowRight } from "@icon-park/react";
import { Grid, Card, Text, Flex, ActionIcon } from "@mantine/core";
import Options from "./options";
import API from "../api/api";
import SDK from "../sdk/sdk";

const Selector = () => {

    const [selected, setSelected] = useState("")

    const handleSelect = (value: string) => {
        setSelected(value)
        // onSelect(value)
    }

    const getContent = (option: string) => {
        switch (option) {
            case "api":
                return <API />
            case "sdk":
                return <SDK />
            default: 
                return <></>
        }
    }

    useEffect(() => {
      
    
      return () => {
    
      }
    }, [selected])
    

    return (<Grid pt={"xl"}>
        <Grid.Col xs={12}>
            <Text fw={600} fz={"xl"} c={"blue"} mb={"xl"} align="center">Select Demo Option</Text>
            <Options onSelect={handleSelect} />
            {getContent(selected)}
        </Grid.Col>
    </Grid>)
}

export default Selector;